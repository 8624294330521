<template>
  <b-row>

    <!-- Description -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Description"
        rules="required"
      >
        <b-form-group
          label="Description"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="salesTargetData.description"
            :state="getValidationState(validationContext)"
            placeholder="Description"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Target Agency -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Target Agency"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Target Agency"
          label-for="target-agency"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="salesTargetData.agency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="accountTypeOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="target-agency"
            :disabled="formType === 'edit'"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Target Count -->
    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Target Count"
        rules="required|between:1,9999"
      >
        <b-form-group
          label="Target Count"
          label-for="target-count"
        >
          <b-form-input
            id="target-count"
            v-model="salesTargetData.targetCount"
            :state="getValidationState(validationContext)"
            placeholder="Target Count"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="Start Date"
        rules="required"
      >
        <b-form-group
          label="Start Date"
          label-for="start-date"
        >
          <flat-pickr
            v-model="salesTargetData.start_date"
            class="form-control"
            :config="config.start"
            :disabled="formType === 'edit'"
            @on-change="onStartChange"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <b-col sm="6">
      <validation-provider
        #default="validationContext"
        name="End Date"
        rules="required"
      >
        <b-form-group
          label="End Date"
          label-for="end-date"
        >
          <flat-pickr
            v-model="salesTargetData.end_date"
            class="form-control"
            :config="config.end"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,
    flatPickr,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    salesTargetData: {
      type: Object,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    accountTypeOptions: {
      type: Array,
      required: true,
    },
    formType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      config: {
        start: {
          enableTime: true,
          enableSeconds: true,
          minDate: new Date(),
          maxDate: null,
        },
        end: {
          enableTime: true,
          enableSeconds: true,
          minDate: null,
        },
      },
    }
  },
  methods: {
    onStartChange(selectedDates, dateStr, instance) {
      this.config.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.config.start.maxDate = dateStr
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      //  Form
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
