import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import salesTargetStoreModule from '../salesTargetStoreModule'
import router from '@/router'

export default function useSalesTargetsForm() {
  const SALES_TARGET_APP_STORE_MODULE_NAME = 'app-sales-target'

  // Register module
  if (!store.hasModule(SALES_TARGET_APP_STORE_MODULE_NAME)) store.registerModule(SALES_TARGET_APP_STORE_MODULE_NAME, salesTargetStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(SALES_TARGET_APP_STORE_MODULE_NAME)) store.unregisterModule(SALES_TARGET_APP_STORE_MODULE_NAME)
  })

  const blankSalesTargetData = {
    description: null,
    agency: null,
    start_date: null,
    end_date: null,
    targetCount: null,
  }

  const salesTargetData = ref(JSON.parse(JSON.stringify(blankSalesTargetData)))

  // *===============================================---*
  // *--------- ACTIONS ---------------------------------------*
  // *===============================================---*
  const fetchSalesTarget = async () => {
    try {
      const response = await store.dispatch('app-sales-target/fetchSalesTarget', { id: router.currentRoute.params.id })
      // eslint-disable-next-line prefer-destructuring
      salesTargetData.value = response.data.data[0].target
    } catch (error) {
      if (error.response.status === 404) {
        salesTargetData.value = undefined
      }
    }
  }

  const createSalesTarget = async () => {
    try {
      const response = await store.dispatch('app-sales-target/createSalesTarget', salesTargetData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'sales-targets-list' })
    } catch (error) {
      console.error(error)
    }
  }

  const updateSalesTarget = async () => {
    try {
      const response = await store.dispatch('app-sales-target/updateSalesTarget', salesTargetData.value)
      if (response.data.status) {
        router.push({ name: 'sales-targets-list' })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    //  Data
    salesTargetData,

    //  Actions
    fetchSalesTarget,
    createSalesTarget,
    updateSalesTarget,
  }
}
